import React from "react";
import one from "../../../assets/projects/organic/one.png";
import org from "../../../assets/projects/organic/org.jpg";
import two from "../../../assets/projects/organic/two.png";
import three from "../../../assets/projects/organic/three.png";
import four from "../../../assets/projects/organic/img1.png";
import five from "../../../assets/projects/organic/five.png";
import six from "../../../assets/projects/organic/six.png";
import seven from "../../../assets/projects/organic/seven.png";
import eight from "../../../assets/projects/organic/eight.png";
import nine from "../../../assets/projects/organic/nine.png";
import ten from "../../../assets/projects/organic/ten.png";
import eleven from "../../../assets/projects/organic/eleven.png";
import tweleve from "../../../assets/projects/organic/tweleve.png";
import fish from "../../../assets/projects/organic/fish.png";
import map from "../../../assets/projects/organic/map.png";
import log from "../../../assets/projects/organic/log.png";
import ReactPlayer from "react-player";
import { useMediaQuery } from "@chakra-ui/react";
const FlyOrganic = () => {
  const [isSmallerThan1220] = useMediaQuery("(max-width: 1220px)");
  const [isSmallerThan992] = useMediaQuery("(max-width: 992px)");
  const [isSmallerThan756] = useMediaQuery("(max-width: 756px)");
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");
  const [isSmallerThan360] = useMediaQuery("(max-width: 360px)");
  return (
    <div className="">
      <img src={org} alt="h-80 md:h-auto" />
      <div className="max-w-7xl mx-auto flex items-center space-x-3 gap-10 pb-8">
        <img src={log} alt="" className="h-80" />
        <div className="flex flex-col items-start space-y-5 ">
          <p className="text-justify font-normal text-gray-700">
            Ethiopian Airlines, the world renowned and celebrated aviation that
            has single handedly rebranded the African aviation experience,
            serving as the country’s only and the region’s best airlines service
            provider for the past 75 years.
          </p>
          <p className="text-justify font-normal text-gray-700">
            Its top-of-the-line catering puts out its vast expertise that
            embodies authentic Ethiopian culinary culture. To share this
            expertise as an experience for anyone exploring Ethiopia; it set out
            to create a collection of extensive gastronomic and cultural
            experiences.
          </p>
        </div>
      </div>
      <ReactPlayer
        playing={true}
        // Disable download button
        config={{
          file: {
            attributes: { controlsList: "nodownload" },
            file: {
              attributes: { preload: "auto" },
              forceAudio: true,
            },
          },
        }}
        // Disable right click
        onContextMenu={(e) => e.preventDefault()}
        light={map}
        // Your props
        url={`https://youtu.be/f1a9B4tKPho`}
        className="react-player"
        controls
        width="100%"
        height={
          isSmallerThan360
            ? "160px"
            : isSmallerThan480
            ? "200px"
            : isSmallerThan756
            ? "280px"
            : isSmallerThan992
            ? "370px"
            : isSmallerThan1220
            ? "470px"
            : "550px"
        }
      />
      <p className="py-10 font-normal text-gray-700 max-w-4xl mx-auto text-center">
        To this end we collaborated with the Ethiopian Airlines to design the
        Fly Ethiopian – Go Organic Campaign– incorporating multicultural and
        Ethiopian culinary experiences into a recipe book with an original look
        and feel.
      </p>
      <div className="  w-full flex flex-col items-center space-y-2">
        <img src={two} alt="" />
        <div className=" grid grid-cols-1 md:grid-cols-2 gap-3">
          <img src={three} alt="" />
          <img src={four} alt="" />
        </div>
        <img src={five} alt="" />
        <img src={six} alt="" />
        <div className=" max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <img src={eight} alt="" />
          <img src={nine} alt="" />
          <img src={tweleve} alt="" />
          <img src={ten} alt="" />
        </div>
        {/*  */}
        <div className=" p-5 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <p className="font-normal text-gray-700 text-justify">
              Exploring unchartered territories, reimagining our culture, we
              created a campaign that established Ethiopian Airlines as pioneer
              in the standardization of adorned national cuisines, culture and
              heritage. We gave life to the recipe book with insights of
              influential figures that served in building the deeply rooted culinary
              culture.{" "}
            </p>
            <p className="font-normal text-gray-700 text-justify pt-5">
              To this end we collaborated with the Ethiopian Airlines to design
              the Fly Ethiopian – Go Organic Campaign– incorporating
              multicultural and Ethiopian culinary experiences into a recipe
              book with an original look and feel
            </p>
            <img src={seven} alt="" />
          </div>
          <div>
            <div className="flex items-center space-y-2">
              <img src={eleven} alt="" className="h-80 w-full object-cover" />
              <img src={fish} alt="" className="h-80  w-full object-cover" />
            </div>
            <p className="font-normal text-gray-800 p-3 md:p-16 text-justify">
              Tying together the rich and extensive culinary art of the country,
              we planned, promoted and designed the launching event that took
              invitees through an immersive journey of the campaign with a
              screening that displayed that intent, relevance and impact that
              its brings to the country, the airline and those looking to
              explore Ethiopia through a gastronomic journey.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlyOrganic;
