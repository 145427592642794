import { Center, Stack } from "@chakra-ui/react";
import { Suspense } from "react";
import { ClimbingBoxLoader, DotLoader } from "react-spinners";
import AllRoutes from "./routes/AllRoutes";
import Footer from "./utilities/components/Footer";
import { GlobalStyles } from "./utilities/components/globalStyles.elements";
import MainNavigation from "./utilities/components/MainNavigation";
import ScrollToTop from "./utilities/funcitons/ScrollToTop";

function App() {
  let routes = <AllRoutes />;
  return (
    <GlobalStyles>
      {" "}
      <ScrollToTop />
      <Stack spacing={0}>
        <Stack spacing={{ base: "-75px", sm: "-105px" }}>
          <MainNavigation />
          <Suspense
            fallback={
              <Center alignSelf={"center"} minH="500px">
                <DotLoader size={60} color="#319284" />
              </Center>
            }
          >
            {routes}
          </Suspense>
        </Stack>
        <Footer />
      </Stack>
    </GlobalStyles>
  );
}

export default App;
