import styled from "styled-components";
import ceraProBlack from "../../assets/fonts/Cera Pro Black.otf";

export const GlobalStyles = styled.div`
  @font-face {
    * {
      font-family: Imported;
      src: url(${ceraProBlack});
    }
  }
`;

export const Nav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 150;
  min-height: 60px;
  background: ${({ transparentBg, isHome, isAbout, isProject }) =>
    transparentBg
      ? "transparent"
      : isHome
      ? "#000"
      : isAbout
      ? "#EAEAEA"
      : isProject
      ? "#BFCFB4"
      : "#FFF"};
  @media screen and (max-width: 992px) {
    height: 4rem;
  }
`;
