import { Box, HStack, Img, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { socialMediaIcons } from "../Data";
import mainLogo from "../../assets/logo/whiteLogo.svg";
import footerGradient from "../../assets/Footer .svg";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const isContact =
    pathname?.toLowerCase() === "/contact" ||
    pathname?.toLowerCase() === "/contact/";
  return (
    <div>
      {!isContact && (
        <div className=" p-2 flex flex-col  items-center justify-center space-y-3 w-full max-w-4xl mx-auto py-10">
          <h4 className="font-semibold text-xl text-center">
            Are you an innovative and inspired brand looking to grow?
          </h4>
          <h2 className="font-extrabold text-xl md:text-4xl">
            LETS MAKE SOMETHING
          </h2>
          <h1 className="font-extrabold text-xl md:text-4xl text-gray-600 pl-2">
            IMPACTFUL TOGETHER
          </h1>
          <div className="flex items-center justify-center w-full">
            <button
              onClick={() => navigate("/contact")}
              className="bg-black text-white font-medium p-3 px-10 w-fit "
            >
              Contact
            </button>
          </div>
        </div>
      )}

      <Stack
        spacing={{
          base: "-14%",
          sm: "-8.5%",
          md: "-6.5%",
          lg: "-5.5%",
          xl: "-4.5%",
        }}
      >
        <Stack
          pt="90px"
          pb="40px"
          pl={{ base: "3%", md: "4%" }}
          pr={"6%"}
          justify={"space-between"}
          align={{ base: "start", md: "center" }}
          direction={{ base: "column", md: "row" }}
          bg="#000"
          color={"#FFF"}
        >
          <div className="flex flex-col items-start space-y-3">
            <Img w={{ base: "180px", sm: "180px" }} src={mainLogo} />
            <HStack spacing={"20px"}>
              {socialMediaIcons?.map((socialMediaIcon, index) => (
                <a target={"_blank"} href={socialMediaIcon?.to} key={index}>
                  <Box cursor={"pointer"}>{socialMediaIcon?.icon}</Box>
                </a>
              ))}
            </HStack>
          </div>
          <Stack spacing={"20px"}>
            <Stack spacing={"5px"}>
              <Text fontSize={14}>
                SPOTLIGHT COMMUNICATIONS & MARKETING LTD
              </Text>
              <a href="tel:+251 91 336 7871">
                <Text>+251 91 398 6445</Text>
              </a>
              <Text fontSize={14}>info@spotlightplc.com</Text>
              <Text fontSize={14}>
                Africa avenue, DG Geda Tower 7th Floor #705
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default Footer;
