import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { navLinks } from "../Data";

const NavigationDrawer = ({ isOpen, onClose }) => {
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size={"xs"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <HStack>
            <Box flexGrow={"1"} />
            <Box onClick={onClose} cursor={"pointer"}>
              <AiOutlineClose size="1.5rem" />
            </Box>
          </HStack>
        </DrawerHeader>
        <DrawerBody>
          <Divider
            h="98%"
            alignSelf={"end"}
            borderWidth={"3px"}
            borderRightColor="black"
            borderLeftColor="#FFF"
            borderTopColor="#FFF"
            borderBottomColor="#FFF"
            orientation="vertical"
          />
        </DrawerBody>
        <DrawerFooter pb="60px" borderTopWidth="1px">
          <Stack
            mr="3%"
            fontSize={"20px"}
            align="end"
            justify={"end"}
            fontWeight={"bold"}
          >
            {navLinks?.map((navLink, index) => (
              <Link onClick={onClose} key={index} to={navLink?.to}>
                <Text
                  _hover={{
                    textDecoration: "underline",
                  }}
                  fontSize={"1xl"}
                >
                  {navLink?.name}
                </Text>
              </Link>
            ))}
          </Stack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default NavigationDrawer;
