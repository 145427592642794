import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";

export const navLinks = [
  {
    name: "HOME",
    to: "/",
  },
  {
    name: "ABOUT",
    to: "/about",
  },
  { name: "PROJECTS", to: "/projects" },
  { name: "CONTACTS", to: "/contact" },
];

export const socialMediaIcons = [
  { icon: <BsInstagram size="1.3rem" />, to: "" },
  {
    icon: <BsYoutube size="1.3rem" />,
    to: "https://www.youtube.com/channel/UClGPRAKYtmymoGlxSOM41gQ?view_as=subscriber",
  },
  {
    icon: <BsFacebook size="1.3rem" />,
    to: "https://www.facebook.com/spotlight.et",
  },
  {
    icon: <BsTwitter size="1.3rem" />,
    to: "https://www.linkedin.com/in/spotlight-ethiopia/",
  },
  {
    icon: <FaTiktok size="1.3rem" />,
    to: "https://www.linkedin.com/in/spotlight-ethiopia/",
  },
  {
    icon: <BsLinkedin size="1.3rem" />,
    to: "https://www.linkedin.com/in/spotlight-ethiopia/",
  },
];
