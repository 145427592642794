import { Box, HStack, Img, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import whiteLogo from "../../assets/logo/whiteLogo.svg";
import blackLogo from "../../assets/logo/blackLogo.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import NavigationDrawer from "./NavigationDrawer";
import { Nav } from "./globalStyles.elements";
import { Link, NavLink, useLocation } from "react-router-dom";
import { navLinks } from "../Data";
import myFont from "../../assets/fonts/OrionSans-Regular.ttf";
import mainLogo from "../../assets/logo/whiteLogo.svg";
const MainNavigation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation();
  const { pathname } = location;

  const isHome = pathname === "/";
  const isContact =
    pathname?.toLowerCase() === "/contact" ||
    pathname?.toLowerCase() === "/contact/";
  const isAbout =
    pathname?.toLowerCase() === "/about" ||
    pathname?.toLowerCase() === "/about/";
  const isProject =
    pathname?.toLowerCase() === "/projects" ||
    pathname?.toLowerCase() === "/projects/";
  const isProjectdetail =
    pathname?.toLowerCase() === "/projects/1" ||
    pathname?.toLowerCase() === "/projects/2" ||
    pathname?.toLowerCase() === "/projects/3";
  // console.log({ isProjectdetail });
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window?.addEventListener("scroll", handleScroll);

    return () => {
      window?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Nav
      isHome={isHome}
      isAbout={isAbout}
      isProject={isProject}
      transparentBg={
        scrollPosition < 100 && !isProject && isHome && isAbout
          ? true
          : scrollPosition < 500 && isProject
          ? true
          : false
      }
    >
      <HStack
        bg={isHome ? "white" : isAbout ? "#d7d7d7" : isContact && "black"}
        px={{ base: 4, md: 14 }}
        py={{ base: 3, md: 3 }}
        justify="space-between"
      >
        <NavigationDrawer isOpen={isOpen} onClose={onClose} />
        <Link to="/">
          <Img
            cursor={"pointer"}
            w={{ base: "150px", sm: "150px" }}
            src={
              isContact
                ? mainLogo
                : isHome
                ? blackLogo
                : isAbout
                ? blackLogo
                : isProject
                ? whiteLogo
                : blackLogo
            }
          />
        </Link>
        <div className="hidden md:flex items-center space-x-5">
          {navLinks?.map((navLink, index) => (
            <NavLink
              onClick={onClose}
              key={index}
              to={navLink?.to}
              style={({ isActive }) => ({
                padding: 5,
                fontFamily: "",
                color: isProject
                  ? isActive
                    ? "#987e43"
                    : "white"
                  : isProjectdetail
                  ? isActive
                    ? "#987e43"
                    : "black"
                  : isHome
                  ? isActive
                    ? "#987e43"
                    : "black"
                  : isAbout
                  ? isActive
                    ? "#987e43"
                    : "black"
                  : isContact
                  ? isActive
                    ? "white"
                    : "#9ca3af"
                  : "black",
                fontWeight: "normal",
              })}
            >
              <Text
                css={`
                  @font-face {
                    font-family: "My Font";
                    src: url(${myFont}) format("truetype");
                  }
                  font-family: "My Font";
                `}
                fontSize={"18px"}
              >
                {navLink?.name}
              </Text>
            </NavLink>
          ))}
        </div>
        <Box
          onClick={onOpen}
          cursor={"pointer"}
          display={{ md: "none", lg: "none" }}
        >
          <GiHamburgerMenu
            color={
              isHome ? "#000" : isAbout ? "#000" : isProject ? "#FFF" : "#000"
            }
            size="2rem"
          />
        </Box>
      </HStack>
    </Nav>
  );
};

export default MainNavigation;
