import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import FlyOrganic from "../project/projectDetail/organic/FlyOrganic";

const FindingLucy = React.lazy(() =>
  import("../project/projectDetail/findingLucy/FindingLucy")
);

const Home = React.lazy(() => import("../home/Home"));
const About = React.lazy(() => import("../about/About"));
const AfricanEUCamp = React.lazy(() =>
  import("../project/projectDetail/africaEUCampaign/AfricanEUCamp")
);
const GreenLegacy = React.lazy(() =>
  import("../project/projectDetail/greenLegacy/GreenLegacy")
);

const ProjectList = React.lazy(() =>
  import("../project/projectList/ProjectList")
);

const ShegaProject = React.lazy(() =>
  import("../project/projectDetail/shega/ShegaProject")
);

const AsdemamiTewled = React.lazy(() =>
  import("../project/projectDetail/asdemami/AsdemamiTewled")
);
const Dankira = React.lazy(() =>
  import("../project/projectDetail/dankira/Dankira")
);

const Contact = React.lazy(() =>
  import("../contact/Contact")
);


const AllRoutes = () => {

  return (
   <div>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/projects" element={<ProjectList />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/projects/1" element={<GreenLegacy />} />
      <Route path="/projects/2" element={<AfricanEUCamp />} />
      <Route path="/projects/3" element={<ShegaProject />} />
      <Route path="/projects/4" element={<FindingLucy />} />
      <Route path="/projects/5" element={<AsdemamiTewled />} />
      <Route path="/projects/6" element={<Dankira />} />
      <Route path="/projects/7" element={<FlyOrganic />} />
      <Route path="/contact" element={<Home />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
   </div>
  );
};

export default AllRoutes;
